<template>
    <div class="CompaDetail xi">
        <hd-com></hd-com>
        <div class="banner">
            <div class="banner-text">
                <h3>配伍详情</h3>
                <p>当前位置：<router-link to="/Compatibility">配伍</router-link><i class="el-icon-arrow-right"></i><a>配伍结果</a></p>
            </div>
        </div>
        <div class="content-wrap">
            <div class="jb_table">
                <table>
                    <tbody v-if="compareDetail">
                        <tr>
                            <td class="tit">名称</td>
                            <td class="tit" width="525">
                                <a href="javascript:void(0);">{{compareDetail.sourceDrugName}}</a>
                            </td>
                            <td class="zy-name" width="525">
                                <a href="javascript:void(0);">{{compareDetail.targetDrugName}}</a>
                            </td>
                        </tr>
                        <tr>
                            <td class="tit">类别</td>
                            <td>{{compareDetail.sourceDrugCategory}}</td>
                            <td>{{compareDetail.targetDrugCategory}}</td>
                        </tr>
                        <tr>
                            <td class="tit">成分</td>
                            <td><span v-html="compareDetail.sourceDrugComposition"></span></td>
                            <td class="">
                                <span v-html="compareDetail.targetDrugComposition"></span>
                            </td>
                        </tr>
                        <template v-if="compareDetail.tips">
                            <template v-for="(types) in tipGroup(compareDetail.tips)">
                                <tr v-if="types.value.some(i=>i.categoryCode != 8)">
                                    <td class="tit" :rowspan="1" v-if="types.keys == 10">配伍结果</td>
                                    <td class="tit" :rowspan="types.value.some(i=>i.title == '临床证据')? types.value.length -1 : types.value.length " v-if="types.keys == 4">临床建议</td>
                                    <td class="tit" :rowspan="1" v-if="types.keys == 1">禁忌机理</td>
                                <td :colspan="2">
                                    <tr colspan="1" class="ch" v-for="(val,index2) in types.value.filter(i=>i.title != '临床证据')" :key="index2">
                                        <div class="dtxt" v-html="namehtmlraw(val)">
                                        </div>
                                    </tr>
                                </td>
                                </tr>
                                <tr v-if="types.value.some(i=>i.title == '临床证据')">
                                    <td class="tit" :rowspan="types.value.filter(i=>i.title == '临床证据').length ">临床证据</td>
                                    <td :colspan="types.value.length">
                                <tr colspan="1" class="ch" v-for="(val,index2) in types.value.filter(i=>i.title == '临床证据')" :key="index2">
                                    <div class="dtxt" v-html="namehtmlraw(val)">
                                    </div>
                                </tr>
                                </td>
                                </tr>
                                <tr v-if="types.value.some(i=>i.categoryCode == 8)">
                                    <!-- <td class="tit" :rowspan="types.value.length " v-if="types.value.some(i=>i.title == '机制')" >机制</td> -->
                                    <!-- <td class="tit" :rowspan="types.value.length " v-if="types.value.some(i=> i.title == '处理')" >处理</td> -->
                                    <td class="tit" :rowspan="types.value.length ">结果</td>
                                    <td :colspan="types.value.length">
                                        <tr colspan="1" class="ch"  v-for="(val,index2) in types.value" :key="index2">
                                        <div class="dtxt" v-html="namehtmlraw(val)">
                                        </div>
                                        </tr>
                                    </td>
                                </tr>
                            </template>
                            <tr>
                            </tr>
                        </template>
                        <tr v-if="(compareDetail.compatibilityType == 1 || compareDetail.compatibilityType == 2 || compareDetail.compatibilityType == 5) && (compareDetail.sourceDrugConsiderations ||compareDetail.targetDrugConsiderations)">
                            <td class="tit">注意事项</td>
                            <td class="" v-if="compareDetail.sourceDrugConsiderations">
                                <div class="dtxt" v-html="compareDetail.sourceDrugConsiderations.replace(/\n/g, '</p> <p>')">
                                </div>
                            </td>
                            <td class="" v-if="compareDetail.targetDrugConsiderations">
                                <div class="dtxt" v-html="compareDetail.targetDrugConsiderations.replace(/\n/g, '</p> <p>')">
                                </div>
                            </td>
                        </tr>
                        <tr v-if="compareDetail.sourceDrugInteract && compareDetail.targetDrugInteract">
                            <td class="tit">相互作用</td>
                            <td class="" v-if="compareDetail.sourceDrugInteract">
                                <div class="dtxt" v-html="compareDetail.sourceDrugInteract.replace(/\n/g, '</p> <p>')">
                                </div>
                            </td>
                            <td class="" v-if="compareDetail.targetDrugInteract">
                                <div class="dtxt" v-html="compareDetail.targetDrugInteract.replace(/\n/g, '</p> <p>')">
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <ft-com></ft-com>
    </div>
</template>

<script>
    import hdCom from '../components/header' //引入组件
    import ftCom from '../components/footer' //引入组件
    import { tipGroup, namehtmlraw } from "../utils/Compatibility";
    import { Compatibility } from '../components/Compatibility/Compatibility'
    export default {
        name: "CompaDetail",
        components: {
            hdCom,
            ftCom
        },
        data() {
            var compatibility = new Compatibility(this.TokenClient, this.Services.Drug)
            return {
                sourceType: this.$route.query.sourceType, //
                sourceHashKey: this.$route.query.sourceHashKey, //
                targetType: this.$route.query.targetType, //
                targetHashKey: this.$route.query.targetHashKey, //
                compareDetail: [],
                compatibilityDomain: compatibility
            }
        },
        methods: {
            //详情获取
            async getCompareDetail() {
                var _this = this
                _this.compatibilityDomain.Detail(_this.sourceType, _this.sourceHashKey, _this.targetType, _this.targetHashKey, function (data) {
                    _this.compareDetail = data.data.compatibility[0]
                }, function (error) { console.log('配伍请求异常:', error) })
            },
            //tip分组
            tipGroup(list) {
                var group = tipGroup(list)
                var results = []
                for (var i = 0; i < group.length; i++) {
                    if (group[i].keys == 2 || group[i].keys == 3 || group[i].keys == 5) {
                        $.extend(results, group[i].value)
                        group.splice(i, 1);
                        i = 0
                    }
                }
                var newgroup = group
                if (results.length > 0) {
                    var res = {
                        keys: 10,
                        value: results
                    }
                    newgroup = group.concat(res)
                }
                return newgroup
            },
            namehtmlraw(item) {
                return namehtmlraw(item)
            },
        },
        mounted() {
            this.getCompareDetail()
        }
    }
</script>

<style scoped>
    @import "../assets/css/header.css";
    @import "../assets/css/footer.css";

    .banner {
        height: 160px;
        background: url("../assets/img/ypk_banner.jpg")center no-repeat;
    }

    .banner-text {
        position: relative;
        width: 1200px;
        margin: 0 auto;
    }

        .banner-text h3 {
            padding-top: 45px;
            font-size: 26px;
            font-weight: normal;
            color: #ffffff;
            text-transform: uppercase;
        }

            .banner-text h3 span {
                font-size: 14px;
            }

        .banner-text p {
            margin-top: 10px;
            font-size: 14px;
            color: #ffffff;
        }

            .banner-text p a {
                color: #ffffff;
                font-size: 14px;
                margin: 0 5px;
            }
    /*内容部分*/
    .content-wrap {
        background: #f8f8f8;
    }

    .jb_table {
        width: 1200px;
        margin: 0 auto;
        padding: 50px 0;
    }

        .jb_table * {
            font-size: 16px !important;
        }

        .jb_table table {
            width: 100%;
            height: auto;
            background: #ffffff;
            border-collapse: collapse;
        }

        .jb_table td, .jb_table th {
            padding: 20px 0;
            font-size: 22px;
            line-height: 1.7;
            text-align: center;
            border: 1px #e9e9e9 solid;
        }

        .jb_table tr .tit {
            background: #f4f4f4 !important;
        }

        .jb_table tr td.zy-name {
            background: #01bbb3 !important;
        }

        .jb_table tr td a {
            font-size: 22px !important;
        }

        .jb_table tr td.zy-name a {
            color: #FFFFFF;
        }

        .jb_table tr td:nth-child(odd) {
            background: #fafafa;
        }

    .dtxt {
        padding: 0 18px;
        text-align: left;
    }

        .dtxt p {
            padding: 10px;
        }
</style>
